<template>
    <div class="catalogues">
      
      <div class="catalogues__actions">
          <CreateBranchButton @showModal="showModal"/>
          <BranchList/>
      </div>
  
      <ModalBranches
          @closeModal="showModal"
          v-show="isActiveModal"    
      />
  
    </div>
  </template>
  
  <script>
  export default {
      name: 'Catalogues',
      components: {
          CreateBranchButton: () => import('./createBranchButton'),
          ModalBranches: () => import('./createBranchModal'),
          BranchList: () => import('./branchList.vue')
      },
      data() {
          return {
              isActiveModal: false
          }
      },
      methods: {
          showModal() {
              this.isActiveModal = !this.isActiveModal
          }
      },
  }
</script>
